import React, { useEffect } from 'react';
import useSound from 'use-sound';

import { Wheel } from 'react-custom-roulette';
import { ItemAPI, PlayAPI } from '@services/api';
import _ from 'lodash';
import utils from '@helper/utils';

// css
import './main.css';
import './App.css';
import './fireworks.scss';
import {
  Col,
  FlexboxGrid,
  Panel,
  Form,
  Button,
  toaster,
  Modal,
  Row,
  Table,
} from 'rsuite';

// json dummy data
import json from './dummy.json';

const backgroundColors = [
  // '#10932D',
  // '#0472A4',
  // '#183562',
  // '#9E5A97',
  // '#C44A52',
  // '#DA6F44',
  // '#E5A143',
  '#D01A04',
  '#F9E799',
];

const nominal = ['5.000', '50.000', '10.000', '1.000.000'];

const textColors = ['#F9E799', '#000000'];
const outerBorderColor = '#D08A3A';
const outerBorderWidth = 14;
const innerBorderColor = '#ffffff';
const innerBorderWidth = 20;
const innerRadius = 10;
const fontFamily = 'Allerta Stencil';
const fontSize = 15;
const radiusLineColor = '#0E3A46';
const radiusLineWidth = 2;

function App(): JSX.Element {
  const [mustSpin, setMustSpin] = React.useState(false);
  const [isFireworkShow, showFirework] = React.useState(false);
  const [showModal, setModal] = React.useState(false);
  const [data] = React.useState(json);
  const [showHistoryModal, setHistoryModal] = React.useState(false);
  const [showPopupModal, setPopupModal] = React.useState(false);
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);
  const [prizeNumber, setPrizeNumber] = React.useState(0);
  const [payload, setPayload] = React.useState({
    // username: '', // @temp
    voucherCode: '',
  });
  const [selectedVoucherCode, setVoucher] = React.useState('');
  const [playBgMusic, { stop: stopBgMusic }] = useSound(
    // eslint-disable-next-line max-len
    'https://luckyspin-bucket.s3.ap-southeast-1.amazonaws.com/sounds/bg007.mp3',
  );
  const [playMusic, { stop: stopMusic }] = useSound(
    // eslint-disable-next-line max-len
    'https://luckyspin-bucket.s3.ap-southeast-1.amazonaws.com/sounds/roulette.mp3',
  );
  const [playFireworkMusic, { stop: stopFireworkMusic }] = useSound(
    // eslint-disable-next-line max-len
    'https://luckyspin-bucket.s3.ap-southeast-1.amazonaws.com/sounds/firework-sound-effect.mp3',
  );

  const [items, setItems] = React.useState([{ option: 'default', id: 0 }]);
  const [prizeName, setPrizeName] = React.useState('');

  const isButton2Disabled = true;

  // Fetch Items API
  useEffect(() => {
    const getItems = async (): Promise<void> => {
      try {
        const res = await ItemAPI.list();
        const list = _.get(res, 'data') || [];
        setItems(list);
      } catch (e) {
        const message: string | any =
          _.get(e, 'response.data.message') || _.get(e, 'message');
        toaster.push(utils.buildErrorMessage(message));
      }
    };

    getItems();
    playBgMusic();

    return () => {
      stopBgMusic();
    };
  }, [playBgMusic, stopBgMusic, data]);

  const clearPayload = (): void => {
    setPayload({ voucherCode: '' });
  };

  // Handle when spin button clicked
  const handleSpinClick = async (): Promise<void> => {
    try {
      const res = await PlayAPI.start(payload);
      const id = _.get(res, 'data.itemId');
      const idx = _.findIndex(items, { id });
      const it = _.find(items, { id });

      setButtonDisabled(true);
      setPrizeNumber(idx);
      setPrizeName(_.get(it, 'text') || '');
      setMustSpin(true);
      setVoucher(payload.voucherCode);

      stopBgMusic();
      playMusic();
    } catch (e) {
      const message: string | any =
        _.get(e, 'response.data.message') || _.get(e, 'message');
      toaster.push(utils.buildErrorMessage(message));
    } finally {
      clearPayload();
    }
  };

  const maskText = (text: string): string => {
    return `${text.substring(0, 2)}*****`;
  };

  // Handle when text changed
  const onFormValueChanged = (v: object | any): void => {
    _.set(v, 'voucherCode', String(v.voucherCode).toUpperCase());

    setPayload(v);
  };

  const renderModal = (): JSX.Element => (
    <Modal
      backdrop="static"
      open={showModal}
      onClose={() => {
        setModal(false);
        playBgMusic();
        stopFireworkMusic();
        showFirework(false);
      }}
    >
      {isFireworkShow ? (
        <div className="pyro">
          <div className="before" />
          <div className="after" />
        </div>
      ) : null}
      <Modal.Header closeButton>Selamat!</Modal.Header>
      <Modal.Body>
        <p>
          Kode Voucher <b>[{selectedVoucherCode}]</b> berhasil mendapatkan&nbsp;
          <b>{prizeName}</b>. Screen Shot dan claim ke CS kami via WA.
          +62877-1553-4473
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-btn"
          disabled={isButton2Disabled}
          onClick={() => {
            setModal(false);
            playBgMusic();
            showFirework(false);
            stopFireworkMusic();
            // eslint-disable-next-line max-len
            const message = `Kode Voucher *[${selectedVoucherCode}]* berhasil mendapatkan *${prizeName}*.`;
            window.open(
              // eslint-disable-next-line max-len
              `https://api.whatsapp.com/send?phone=6287715534473&text=${encodeURI(
                message,
              )}`,
              '_blank',
            );
          }}
          appearance="subtle"
        >
          Claim Sekarang
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderPopup = (): JSX.Element => (
    <Modal
      backdrop="static"
      open={showPopupModal}
      onClose={() => setPopupModal(false)}
    >
      <Modal.Header closeButton>HARAP DIBACA!!</Modal.Header>
      <Modal.Body>
        <img src="./popup.jpg" width="100%" alt="popup" />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-btn"
          onClick={() => setPopupModal(false)}
          appearance="subtle"
        >
          Sudah Membaca
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderHistoryModal = (): JSX.Element => (
    <Modal
      backdrop="static"
      open={showHistoryModal}
      onClose={() => setHistoryModal(false)}
    >
      <Modal.Header closeButton>50 Daftar Riwayat Terakhir</Modal.Header>
      <Modal.Body>
        <Table
          height={420}
          data={
            showHistoryModal
              ? data.sort((a, b): number => {
                  if (Number(a.winner) > Number(b.winner)) {
                    return -1;
                  }

                  return 1;
                })
              : []
          }
          bordered
          cellBordered
          autoHeight
          rowHeight={64}
        >
          <Table.Column flexGrow={1}>
            <Table.HeaderCell flexGrow={1}>Username</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => maskText(_.get(rowData, 'username'))}
            </Table.Cell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <Table.HeaderCell flexGrow={1}>Winner</Table.HeaderCell>
            <Table.Cell>
              {/* {(rowData) => Number(_.get(rowData, 'winner'))} */}
              {() => nominal[Math.floor(Math.random() * nominal.length)]}
            </Table.Cell>
          </Table.Column>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cancel-btn"
          onClick={() => setHistoryModal(false)}
          appearance="subtle"
        >
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const openHistoryModal = (): void => {
    setHistoryModal(true);
  };

  return (
    <div className="app">
      {renderPopup()}
      {renderModal()}
      {renderHistoryModal()}

      <FlexboxGrid className="container" justify="center" align="middle">
        <FlexboxGrid.Item as={Col} colspan={24} xs={24} sm={11}>
          <Panel
            className="welcome-panel welcome-panel-mobile bounce"
            bodyFill
            bordered
          >
            <p style={{ fontSize: '1.3em' }}>
              {/* <b>EVENT MERDEKA BERSAMA OMASLOT | HUT RI KE - 78!</b> */}
              <b>LUCKY SPIN WHEEL OMASLOT!</b>
            </p>
            <p>Silahkan Masukan Kode Voucher yang Telah Anda Dapatkan!</p>
          </Panel>
          <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeNumber}
            data={(items || []).map((it) => ({ option: _.get(it, 'text') }))}
            backgroundColors={backgroundColors}
            textColors={textColors}
            outerBorderColor={outerBorderColor}
            outerBorderWidth={outerBorderWidth}
            radiusLineColor={radiusLineColor}
            radiusLineWidth={radiusLineWidth}
            fontFamily={fontFamily}
            fontSize={fontSize}
            innerBorderWidth={innerBorderWidth}
            innerBorderColor={innerBorderColor}
            innerRadius={innerRadius}
            pointerProps={{
              src: './spin-arrow.png',
              style: { rotate: '45deg' },
            }}
            onStopSpinning={() => {
              stopMusic();
              setModal(true);

              showFirework(true);
              playFireworkMusic();

              setButtonDisabled(false);

              setMustSpin(false);
            }}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} xs={24} sm={13}>
          <Panel className="welcome-panel bounce" bodyFill bordered>
            <p style={{ fontSize: '1.3em' }}>
              {/* <b>EVENT MERDEKA BERSAMA OMASLOT | HUT RI KE - 78!</b> */}
              <b>LUCKY SPIN WHEEL OMASLOT!</b>
            </p>
            <p>Silahkan Masukan Kode Voucher yang Telah Anda Dapatkan!</p>
          </Panel>
          <Panel bodyFill bordered>
            <Form
              fluid
              key="start-play-form"
              formValue={payload}
              onChange={onFormValueChanged}
            >
              {/* @temporary */}
              {/* <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>Username</Form.ControlLabel>
                </Col>
                <Form.Control
                  name="username"
                  required
                  placeholder="Masukan username anda"
                />
              </Form.Group> */}
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>Kode Voucher</Form.ControlLabel>
                </Col>
                <Form.Control
                  required
                  name="voucherCode"
                  placeholder="Masukan kode voucher anda"
                />
              </Form.Group>
              <Row>
                <Col xs={12}>
                  <Button
                    block
                    className="spin-btn"
                    onClick={handleSpinClick}
                    disabled={isButtonDisabled}
                    appearance="primary"
                    type="submit"
                  >
                    Spin!
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    block
                    className="spin-btn"
                    onClick={openHistoryModal}
                    appearance="primary"
                  >
                    Riwayat
                  </Button>
                </Col>
              </Row>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </div>
  );
}

export default App;
